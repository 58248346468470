<template>
  <div>
    <v-dialog v-model="isVisibleErrorDialog" max-width="900px"
              :retain-focus="false">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Update Error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{ errorMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmUpdate" max-width="900px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Confirm update</span>
        </v-card-title>
        <v-card-text class="mt-6">

          <span class="headline">Will be apply for <b>{{ totalTeasers }}</b> teasers for
                  <flag class="mx-3" :iso="country"/>
            <b> {{ countryName }} </b>
          </span>
          <p class="text-sm-center text-sm-h3 mt-6">
            Are you sure?

          </p>

        </v-card-text>
        <v-card-text class="text-sm-center">
          <div style="display: flex; justify-content: center">
            <v-checkbox label="Yes" v-model="confirmUpdateYes"></v-checkbox>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmUpdate = false; confirmUpdateYes=false" :disabled="loading">
            Cancel
          </v-btn>

          <v-btn color="error" :disabled="!confirmUpdateYes || loading" @click="onUpdate"
                 :loading="loading">
            Update
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialog" max-width="900px">
      <v-card>
        <v-card-title class="primary">
        <span class="headline">Mass changes for GEO
        </span>

        </v-card-title>
        <v-card-text>
          <v-form v-model="form" ref="form">
            <v-container>


              <v-row>
                <v-col cols="12" sm="12" md="12">
                <span class="headline">Will be apply for <b>{{ totalTeasers }}</b> teasers for
                  <flag class="mx-3" :iso="country"/>
                <b> {{ countryName }} </b>

                </span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-switch label="Stop teasers" v-model="isChangeTeaseStatus"
                                v-if="teaserStatus === 'Active'"
                                :rules="[validateActive]"
                      ></v-switch>
                      <v-switch label="Start teasers" v-model="isChangeTeaseStatus"
                                :rules="[]"
                                v-if="teaserStatus === 'Pause'"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-checkbox :disabled="!isChangeTeaseStatus"
                                  v-if="teaserStatus === 'Active'"
                                  label="Yes"
                                  :rules="[teaserStatusValidate]"
                                  v-model="stopTeases">
                        <template v-slot:prepend>
                          <v-icon color="red">mdi-pause</v-icon>
                        </template>

                      </v-checkbox>


                      <v-checkbox :disabled="!isChangeTeaseStatus"
                                  v-if="teaserStatus === 'Pause'"
                                  label="Yes"
                                  v-model="startTeases">
                        <template v-slot:prepend>
                          <v-icon color="green">mdi-play-circle</v-icon>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>


                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-switch label="Change" :disabled="isChangeTeaseStatus" v-model="isChangePayout"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                          v-model="payout"
                          :disabled="isChangeTeaseStatus || !isChangePayout"
                          label="Payout"
                          type="string"
                          prepend-icon="mdi-cash"
                          :rules="[payoutValidate]"
                      >
                        <template v-slot:append-outer>
                          $
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-switch label="Change" :disabled="isChangeTeaseStatus" v-model="isChangeApprove"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                          v-model="approve"
                          :disabled="isChangeTeaseStatus || !isChangeApprove"
                          label="Approve"
                          type="string"
                          prepend-icon="mdi-check-decagram"
                          :rules="[approveValidate]"
                      >
                        <template v-slot:append-outer>
                          %
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-switch label="Change" :disabled="isChangeTeaseStatus" v-model="isChangeMode"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-select
                          :disabled="isChangeTeaseStatus || !isChangeMode"
                          :items="modesItems"
                          label="Mode"
                          v-model="mode"
                          prepend-icon="mdi-alpha-m-circle-outline"
                          clearable
                          :rules="[modeValidate]"
                      ></v-select>
                    </v-col>

                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-switch label="Change" :disabled="isChangeTeaseStatus" v-model="isChangeClimit"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                          v-model="climit"
                          :disabled="isChangeTeaseStatus || !isChangeClimit"
                          label="c_limit"
                          type="string"
                          prepend-icon="mdi-format-vertical-align-top"
                          :rules="[cLimitValidate]"
                          clearable
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>


                  <v-row>
                    <v-col cols="12" >
                      <hr>
                    </v-col>
                    <v-col cols="12" >

                      <v-radio-group v-model="abTeasers" :disabled="isChangeTeaseStatus">
                        <v-radio
                            label="For A and B teasers"
                            value="all"
                        ></v-radio>
                        <v-radio
                            label="For A"
                            value="a"
                        ></v-radio>
                        <v-radio
                            label="For B"
                            value="b"
                        ></v-radio>
                      </v-radio-group>

                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-switch label="Change" :disabled="isChangeTeaseStatus" v-model="isChangeTitle"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                          v-model="title"
                          :disabled="isChangeTeaseStatus || !isChangeTitle"
                          label="Title"
                          type="string"
                          :rules="[titleValidate]"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-switch label="Change" :disabled="isChangeTeaseStatus" v-model="isChangeBrand"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                          v-model="brand"
                          :disabled="isChangeTeaseStatus || !isChangeBrand"
                          label="Brand"
                          type="string"
                          :rules="[brandValidate]"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-switch label="Change" :disabled="isChangeTeaseStatus" v-model="isChangeLink"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                          v-model="link"
                          :disabled="isChangeTeaseStatus || !isChangeLink"
                          label="Link"
                          type="string"
                          :rules="[linkValidate]"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-switch label="Change" :disabled="isChangeTeaseStatus" v-model="isChangeImg"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                          v-model="img"
                          :disabled="isChangeTeaseStatus || !isChangeImg"
                          label="Image"
                          type="string"
                          :rules="[imgValidate]"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-switch label="Change" :disabled="isChangeTeaseStatus" v-model="isChangeButton_text"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                          v-model="button_text"
                          :disabled="isChangeTeaseStatus || !isChangeButton_text"
                          label="Button text"
                          type="string"
                          :rules="[buttonTextValidate]"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" :disabled="!form" @click="confirmUpdate = true">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'MassChanges',
  props: {
    value: {
      default: false
    },
    totalTeasers: Number,
    country: String,
    countries: {default: []},
    teaserStatus: {
      default: ''
    },
    modes: {default: []},
    allowedDSPTeaserLinks: {default: []},
    searchRequest: {
      default: {}
    },

  },
  data () {
    return {
      dialog: this.value,

      isVisibleErrorDialog: false,
      errorMessage: '',

      confirmUpdate: false,
      confirmUpdateYes: false,

      stopTeases: false,
      startTeases: false,
      isChangeTeaseStatus: false,

      isChangePayout: false,
      isChangeApprove: false,
      isChangeMode: false,
      isChangeClimit: false,
      isChangeBrand: false,
      isChangeTitle: false,
      isChangeLink: false,
      isChangeImg: false,
      isChangeButton_text: false,

      form: false,
      payout: null,
      approve: null,
      mode: null,
      climit: null,
      title: null,
      brand: null,
      link: null,
      img: null,
      allowedDSPTeaserLinksRegexp: [],
      button_text: null,

      loading: false,

      abTeasers: 'all'
    }
  },

  computed: {
    countryName () {
      return this.countries.find(element => element.value === this.country).text
    },
    modesItems: {
      get () {
        if (Object.prototype.hasOwnProperty.call(this.modes, this.$route.params.section)) {
          return this.modes[this.$route.params.section]
        }
        return this.modes.default
      }
    },
  },
  created () {
    this.allowedDSPTeaserLinksRegexp = this.allowedDSPTeaserLinks.map(l => new RegExp(`^https://${l}`))
  },

  mounted () {
    this.$refs.form.validate()
  },

  methods: {
    validateActive (value) {
      if (value === false && (
          this.isChangePayout ||
          this.isChangeMode ||
          this.isChangeApprove ||
          this.isChangeClimit ||
          this.isChangeBrand ||
          this.isChangeLink ||
          this.isChangeButton_text ||
          this.isChangeImg ||
          this.isChangeTitle

      )) {
        return true
      }

      if (this.teaserStatus === 'Active' && value)
        return true

      else if (this.teaserStatus === 'Pause' && value)
        return true

      else return false
    },

    teaserStatusValidate (value) {
      if (this.isChangeTeaseStatus === false) {
        return true
      }

      if (this.teaserStatus === 'Active' && value)
        return true
      else if (this.teaserStatus === 'Pause' && value)
        return true
      else
        return false
    },

    payoutValidate (value) {
      if (this.isChangeTeaseStatus) {
        return true
      }

      if (!this.isChangePayout) {
        return true
      }
      const f = parseFloat(value)
      if (Number.isFinite(f)) {
        if (f <= 0)
          return 'Set more than 0'
        return true
      } else {
        return 'Set more than 0'
      }
    },

    approveValidate (value) {
      if (this.isChangeTeaseStatus) {
        return true
      }

      if (!this.isChangeApprove) {
        return true
      }
      const i = parseInt(value)
      if (Number.isFinite(i)) {
        if (i <= 0)
          return 'Set more than 0'
        if (i <= 10000) {
          return true
        } else if (i > 1000) {
          return 'Set less than 1000'
        }

        return true
      } else {
        return 'Set more than 0'
      }
    },
    modeValidate (value) {
      if (this.isChangeTeaseStatus) {
        return true
      }
      if (this.isChangeMode === false) {
        return true
      }
      const i = parseInt(value)
      if (Number.isFinite(i)) {
        return true
      } else {
        return 'Select mode'
      }
    },
    cLimitValidate (value) {
      if (this.isChangeTeaseStatus) {
        return true
      }

      if (!this.isChangeClimit) {
        return true
      }

      const i = parseFloat(value)
      if (Number.isFinite(i)) {
        if (Math.round(i * 100) > 125 || Math.round(i) === 0) {
          return true
        } else {
          return 'Must be > 1.25'
        }
      } else {
        return 'Must number'
      }
    },
    titleValidate (value) {
      if (this.isChangeTeaseStatus) {
        return true
      }

      if (!this.isChangeTitle) {
        return true
      }

      if (typeof value === 'string') {
        if (value.length === 0) {
          return 'Need string'
        }
      } else {
        return 'Need "string"'
      }

      return true
    },

    brandValidate (value) {
      if (this.isChangeTeaseStatus) {
        return true
      }

      if (!this.isChangeBrand) {
        return true
      }

      if (/https?:\/\//.test(value)) {
        return 'Not URL'
      }

      return true
    },
    linkValidate (value) {
      if (this.isChangeTeaseStatus) {
        return true
      }
      if (!this.isChangeLink) {
        return true
      }
      if (value === null || value === undefined || value.toString().length === 0) {
        return 'Set valid URL'
      }
      if (/ +/.test(value)) {
        return 'Delete blank symbols'
      }
      if (!(/^https:\/\//.test(value))) {
        return 'must be https://'
      }
      if (
          // best.peekclickshndrk.com
          // www.shndrktrk.com
          // track.trcsrchd.xyz
          // www.bestbloglk.com
          /best\.peekclickshndrk\.com/.test(value) ||
          /www\.shndrktrk\.com/.test(value) ||
          /track\.trcsrchd\.xyz/.test(value) ||
          /www\.bestbloglk\.com/.test(value) ||
          /www\.btstracklnk\.com/.test(value)
      ) {
        return 'Invalid Domain'
      } else {
        if (this.allowedDSPTeaserLinksRegexp.some(r => r.test(value))) {
          return true
        }
        return 'Not allowed URL'
      }
    },
    imgValidate (value) {
      if (this.isChangeTeaseStatus) {
        return true
      }
      if (!this.isChangeImg) {
        return true
      }
      if (value === null || value === undefined || value.toString().length === 0) {
        return 'Set valid URL'
      }
      if (/ +/.test(value)) {
        return 'Delete blank symbols'
      }
      if (!(/^https:\/\//.test(value))) {
        return 'must be https://'
      }
    },
    buttonTextValidate (value) {
      if (this.isChangeTeaseStatus) {
        return true
      }

      if (!this.isChangeButton_text) {
        return true
      }

      if (/https?:\/\//.test(value)) {
        return 'Not URL'
      }

      return true
    },

    async onUpdate () {
      const request = {search: this.searchRequest}

      delete request.search.limit
      delete request.search.page
      delete request.search.onModeration

      request.task = {}
      request.taskAbTest = {}

      if (this.isChangeTeaseStatus) {
        if (this.teaserStatus === 'Active') {
          request.task.on_off = false
        } else if (this.teaserStatus === 'Pause') {
          request.task.on_off = true
        }
      } else {
        request.abTeasers = this.abTeasers

        if (this.isChangePayout) {
          request.task.payout = this.payout
        }
        if (this.isChangeMode) {
          request.task.mode = this.mode
        }

        if (this.isChangeApprove) {
          request.task.approve = this.approve
        }

        if (this.isChangeClimit) {
          request.task.c_limit = this.climit
        }

        if (this.isChangeBrand) {
          request.taskAbTest.brand = this.brand
        }

        if (this.isChangeLink) {
          request.taskAbTest.link = this.link
        }

        if (this.isChangeButton_text) {
          request.taskAbTest.button_text = this.button_text
        }

        if (this.isChangeImg) {
          request.taskAbTest.image = this.img
        }
        if (this.isChangeTitle) {
          request.taskAbTest.newTitle = this.title
        }
      }

      if (Object.keys(request.task).length === 0) {
        delete request.task
      }

      if (Object.keys(request.taskAbTest).length === 0) {
        delete request.taskAbTest
      }

      // console.log('onUpdate R', request)

      this.loading = true

      try {
        const res = await api.dspTeaserMassChanges(request)

        if (!res.data.error) {
          this.confirmUpdate = false
          this.dialog = false
          this.$emit('update', true)
        } else {
          console.error('onUpdate', res.data)
        }

      } catch (e) {
        console.error('getDataFromApi', e)
      }

      this.loading = false
    }
  },

  watch: {
    dialog (value) {
      if (!value) {
        this.$emit('input', false)
      }
    },
    isChangeTeaseStatus () {
      this.$refs.form.validate()
    },
    isChangePayout () {
      this.$refs.form.validate()
    },
    isChangeMode () {
      this.$refs.form.validate()
    },
    isChangeApprove () {
      this.$refs.form.validate()
    },
    isChangeClimit () {
      this.$refs.form.validate()
    },
    isChangeBrand () {
      this.$refs.form.validate()
    },
    isChangeLink () {
      this.$refs.form.validate()
    },
    isChangeButton_text () {
      this.$refs.form.validate()
    },
    isChangeImg () {
      this.$refs.form.validate()
    },
    isChangeTitle () {
      this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>

</style>